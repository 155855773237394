import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

// MUI
import {
    Button, Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    Typography,
    Autocomplete,
    FormControlLabel,
    Checkbox
} from "@mui/material"
import Alert from '@mui/material/Alert';
import {BatchState, loadBatchesAsync, setError, setOpenForm} from './batchSlice'


const UploadBatchForm = props => {
    const dispatch = useDispatch()

    const {error, openForm, rateOptions} = useSelector(BatchState)

    const displayRate = rateOptions.length <= 1 ? 'none' : '' 

    const [state, setState] = useState({
        purpose: 'TREATMENT',
        selectedFile: {name:''},
        fileError: error,
        rate: '',
        generateCoverPage: false,
    })

    useEffect(() => {
        if(openForm){
            setOpen(true)
        }
        if(rateOptions){
            const defaultRate = rateOptions.filter(a =>{ return a.isDefault == true} )
            const defaultRateValue = defaultRate[0]?.id

            setState({ ...state, rate: defaultRateValue })
        }
    }, [openForm,rateOptions])

    const { purpose, fileError, selectedFile, rate } = state
    const onChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const setRate = e =>{
        const val = rateOptions.filter(r => r.display === e.target.innerHTML)
        setState({ ...state, rate: val[0].id })
    }
    const onFileChange = e => {
        setState({ ...state, selectedFile: e.target.files[0], loaded: 0,disableSubmit:false })
        setDisableSubmit(false)
    }

    const onChangeCoverPage = e => {
        setState({ ...state, generateCoverPage: e.target.checked })
    }

    /* alert */
    const [open, setOpen] = React.useState(false)
    const [disableSubmit, setDisableSubmit] = React.useState(true)
    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        state.fileError = null
        setOpen(false)
    }

    const onClick = async e => {
        e.preventDefault()
        setDisableSubmit(true)
        const data = new FormData()
        console.log(state.selectedFile.type)
        const isCsv = (state.selectedFile.type === 'text/csv') || (state.selectedFile.type === 'application/vnd.ms-excel')

        if (!isCsv) {
            state.fileError = 'File type is not valid. File uploads must have a .csv file extension'
            setOpen(true)
            return
        }

        data.append('file', state.selectedFile)
        data.append('purpose', state.purpose)
        data.append('rate', state.rate)
        data.append('generateCoverPage', state.generateCoverPage)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/upload`, data, config).catch((error) => {
                if (error) {
                    if( error.response ){
                    console.log(`In UploadForm: ${error.response.data.message}`)
                    dispatch(setError(error.response.data.message))
                    dispatch(setOpenForm(true))
                    
                    }
                }
            })
        } catch (ex) {
            console.log(ex)
        }
        dispatch(loadBatchesAsync())

    }


    return <Fragment>
        {/*Error Popup*/}
        {fileError && <Snackbar open={open} autoHideDuration={9000} style={{ whiteSpace: 'pre-wrap' }} onClose={closeSnackbar} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}>
            <Alert severity={'error'}>{fileError}</Alert>
        </Snackbar>}
        
        <Card variant={'outlined'}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} style={{marginBottom: '15px', textTransform: 'uppercase'}}  >
                    Upload New Patient File
                </Typography>
                <Grid container spacing={3}>

                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <TextField
                                id='filename-display'
                                value={selectedFile?.name}
                                size={'small'}
                                onChange={onChange}
                                disabled
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} style={{display: 'none'}}>
                        <FormControl fullWidth disabled>
                            <InputLabel id="requestType-label">Request Type</InputLabel>
                            <Select
                                id="requestType"
                                name='purpose'
                                value={purpose}
                                onChange={onChange}
                            >
                                <MenuItem value={'TREATMENT'}>Treatment</MenuItem>
                                <MenuItem value={'REQUEST'}>Request</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            component="label"
                            style={{marginTop: '4px'}}
                            size={'small'}
                        >
                            Choose File
                            <input
                                type="file"
                                name='file'
                                onChange={onFileChange}
                                hidden
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200, display: displayRate}} required>
                            <Autocomplete
                                // disablePortal
                                id="rate"
                                options={rateOptions.map(option=>option.display)}
                                sx={{ width: 300 }}
                                size={'small'}
                                onChange={setRate}
                                isOptionEqualToValue={(option, value) => option.display === value.value}
                                renderInput={(params) =>  <TextField {...params} label='Rate'/>}
                                />
                        </FormControl>     
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.generateCoverPage}
                                    onChange={onChangeCoverPage}
                                    name="generateCoverPage"
                                    color="primary"
                                />
                            }
                            label="Generate cover page"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onClick} variant={'contained'} color={'primary'} disabled={disableSubmit}>Upload</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Fragment>
}

export default UploadBatchForm